<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    scrollable
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Course</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <v-form @submit.prevent="saveForm" method="post" id="course-form">
          <v-text-field
            label="Course Name"
            v-model="fields.name"
            ref="courseName"
            type="text"
            outlined
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>
          <v-textarea
            label="Description"
            v-model="fields.description"
            outlined
            :error="errors.hasOwnProperty('description')"
            :error-messages="errors['description']"
          ></v-textarea>
          <v-text-field
            label="Default Green Fees"
            v-model="fields.default_fee"
            type="number"
            prepend-inner-icon="mdi-currency-gbp"
            outlined
            :error="errors.hasOwnProperty('default_fee')"
            :error-messages="errors['default_fee']"
          ></v-text-field>
          <v-text-field
            label="Website"
            v-model="fields.website"
            type="url"
            outlined
            :error="errors.hasOwnProperty('website')"
            :error-messages="errors['website']"
          ></v-text-field>
          <v-textarea
            label="Address"
            v-model="fields.address"
            outlined
            :error="errors.hasOwnProperty('address')"
            :error-messages="errors['address']"
          ></v-textarea>
          <v-textarea
            label="Itinerary Text"
            v-model="fields.itinerary_text"
            outlined
            :error="errors.hasOwnProperty('itinerary_text')"
            :error-messages="errors['itinerary_text']"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="course-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      course: {},
      fields: {
        name: null,
        description: null,
        default_fee: null,
        website: null,
        address: null,
        itinerary_text: null,
      },
      errors: {},
    };
  },

  watch: {
    dialog() {
      if (this.dialog) {
        this.$nextTick().then(() => {
          this.$refs.courseName.focus();
        });
      }
    },
  },

  methods: {
    openForm: function (course = null) {
      if (course !== null) {
        this.isEditing = true;
        this.fields.name = course.name;
        this.fields.default_fee = course.default_fee;
        this.fields.description = course.description;
        this.fields.website = course.website;
        this.fields.address = course.address;
        this.fields.itinerary_text = course.itinerary_text;
        this.course = course;
      }

      this.dialog = true;
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.fields.name = null;
      this.fields.default_fee = null;
      this.fields.description = null;
      this.fields.website = null;
      this.fields.address = null;
      this.fields.itinerary_text = null;
      this.course = {};
    },

    saveForm: function () {
      const appId = this.$route.params.id;
      this.loading = true;
      this.errors = {};

      let payload = {
        appId,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.courseId = this.course.id;
      }

      this.$store
        .dispatch("drum/admin/saveCourse", {
          ...payload,
        })
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>
